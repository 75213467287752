import { Backdrop, Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { FC, useContext, useEffect } from "react";
import { Image } from "react-bootstrap";
import { useIntl } from "react-intl";
import { clearStorage } from "../../utils/clearStorage";
import { tracerkeyDisconnect } from "../../services/tracerkey";
import { AuthContext } from "../../providers/AuthProvider/AuthProvider";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import COLORS from "../../constants/colors";
import logo from "../../images/logo-only-1.png";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: COLORS.white,
        background: "#0D6B9B",
    },
}));

const Logout: FC<RouteComponentProps> = (props) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { logout } = useContext(AuthContext);
    const { history } = props;

    useEffect(() => {
        (async () => {
            clearStorage()
                .then(async () => {
                    tracerkeyDisconnect();
                    logout({ displaySuccessMessage: true });
                })
                .then(() => {
                    window.location.reload();
                })
                .then(() => {
                    history.push(`${ROUTES.SIGNIN}`, {
                        from: window.location.pathname,
                    });
                });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Backdrop className={classes.backdrop} open={true}>
            <div
                style={{
                    position: "fixed",
                    marginTop: 150,
                    color: "#fff",
                    fontSize: 18,
                }}
            >
                {formatMessage({
                    id: "logout.title",
                })}
            </div>
            <CircularProgress style={{ color: "#fafafa" }} size="64px" />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                >
                    <Image
                        src={logo}
                        width="32px"
                        height="auto"
                        className="pl-2"
                    />
                </Typography>
            </Box>
        </Backdrop>
    );
};

export default withRouter(Logout);
