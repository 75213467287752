import config from "../config/config";
import C from "./browsers";

interface IConnectResult {
    address: string;
    encryptionPublicKey: string;
    privateKey: string;
    publicKey: string;
    encapsulation_key: string;
    mlkem_public_key: string;
    mldsa_public_key: string;
    mldsa_private_key: string;
}

export const tracerkeyCheckExtension = async () => {
    const extId = config.tracerkey.extensionId;
    let extensionIds: any =
        extId?.includes(",") && extId?.includes("[") && extId?.includes("]")
            ? JSON.parse(extId || "")
            : extId;

    if (typeof extensionIds === "string") {
        extensionIds = extensionIds.split(",").map((id) => id.trim());
    }

    if (!Array.isArray(extensionIds) || extensionIds.length === 0) {
        return { installed: false }; // Return false if no valid extension IDs are provided
    }

    for (const extensionId of extensionIds) {
        const checkExtensionPromise = new Promise((resolve) => {
            if (!C || !C.runtime || !extensionId) {
                resolve(false);
                return;
            }

            C.runtime.sendMessage(
                extensionId,
                { method: "check_extension" },
                (response) => {
                    if (
                        response &&
                        response.result &&
                        response.result === "tracerkey_installed"
                    ) {
                        resolve({ installed: true, id: extensionId });
                    } else {
                        resolve(false);
                    }
                }
            );
        });

        const result = await checkExtensionPromise;
        if (result && typeof result === "object") {
            return result; // Return the JSON object if an extension is installed
        }
    }

    return { installed: false }; // Return this if none of the extensions are installed
};

// export const tracerkeyCheckExtension = async () => {
//     const checkExtensionPromise = new Promise((resolve, reject) => {
//         if (!C || !C.runtime || !config.tracerkey.extensionId) {
//             resolve(false);
//             return;
//         }

//         C.runtime.sendMessage(
//             config.tracerkey.extensionId,
//             { method: "check_extension" },
//             (response) => {
//                 if (
//                     response &&
//                     response.result &&
//                     response.result === "tracerkey_installed"
//                 ) {
//                     resolve(true);
//                     console.log("TracerKey installed.");
//                 } else {
//                     resolve(false);
//                     console.log("TracerKey not installed.");
//                 }
//             }
//         );
//     });

//     const isTracerkeyInstalled = (await checkExtensionPromise) as boolean;
//     return isTracerkeyInstalled;
// };

export const tracerkeyConnect = async () => {
    const checkExtension: any = await tracerkeyCheckExtension();
    const { id } = checkExtension || {};

    const connectPromise = new Promise(async (resolve, reject) => {
        if (!checkExtension?.id) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(id, { method: "connect" }, (response) => {
            if (response && response.result) {
                resolve(response.result);
            } else {
                resolve(undefined);
            }
        });
    });

    const connectResult = (await connectPromise) as IConnectResult;

    return connectResult;
};

export const tracerkeyDisconnect = async () => {
    const checkExtension: any = await tracerkeyCheckExtension();
    const { id } = checkExtension || {};

    const connectPromise = new Promise((resolve, reject) => {
        if (!id) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(id, { method: "disconnect" }, (response) => {
            if (response && response.result) {
                resolve(response.result);
            } else {
                resolve(undefined);
            }
        });
    });

    const disconnectResult = (await connectPromise) as boolean;

    return disconnectResult;
};

export const tracerkeyEthSign = async (address: string, message?: any) => {
    // const message = populateMessage({
    //     date: new Date(),
    //     website: config.appName,
    //     address,
    // });

    const checkExtension: any = await tracerkeyCheckExtension();
    const { id } = checkExtension || {};

    const ethSignPromise = new Promise((resolve, reject) => {
        if (!id) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(
            id,
            { method: "eth_sign", params: [address, message] },
            (response) => {
                if (response && response.result) {
                    resolve(response.result);
                } else {
                    resolve(undefined);
                }
            }
        );
    });

    const signature = (await ethSignPromise) as string;

    return { message, signature };
};

export const signAuthMessageTracerkey = async (
    displayError: (messageId: string) => void,
    challenge: string
) => {
    const checkExtension: any = await tracerkeyCheckExtension();
    const { installed: isTracerkeyInstalled } = checkExtension || {};

    if (!isTracerkeyInstalled) {
        displayError("error.not.installed.tracerkey");
        return;
    }

    const connectResult = await tracerkeyConnect();

    if (!connectResult || !connectResult.address) {
        console.log("TracerKey connect failed.");
    }

    const address = connectResult.address;

    const { message, signature } = await tracerkeyEthSign(address, challenge);

    return { message, signature };
};

export const isConnected = async (params: boolean) => {
    const checkExtension: any = await tracerkeyCheckExtension();
    const { id } = checkExtension || {};

    const checkConnectionPromise = new Promise((resolve, reject) => {
        if (!C || !C.runtime || !id) {
            resolve(false);
            return;
        }

        C.runtime.sendMessage(
            id,
            { method: "connected", params },
            (response) => {
                if (
                    response &&
                    response.result &&
                    response.result === "tracerkey_connected"
                ) {
                    resolve(true);
                    console.log("TracerKey connected.");
                } else {
                    resolve(false);
                    console.log("Tracerkey connection failed");
                }
            }
        );
    });

    const isConnectionSucess = (await checkConnectionPromise) as boolean;
    return isConnectionSucess;
};

export const tracerkeyEthDecrypt = async (encryptedData: string) => {
    const { address } = await tracerkeyConnect();
    const checkExtension: any = await tracerkeyCheckExtension();
    const { id } = checkExtension || {};

    const ethDecryptPromise = new Promise((resolve, reject) => {
        if (!id) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(
            id,
            { method: "eth_decrypt", params: [encryptedData, address] }, // old eth_decrypt
            (response) => {
                if (response && response.result) {
                    resolve(response.result);
                } else {
                    resolve(undefined);
                }
            }
        );
    });

    const decryptedString = (await ethDecryptPromise) as string | undefined;

    return decryptedString;
};

export const mlkDecrypt = async (ciphertext: string, sharedKey_ct: string) => {
    const connectResult = await tracerkeyConnect();
    const checkExtension: any = await tracerkeyCheckExtension();
    const { id } = checkExtension || {};

    if (!connectResult || !connectResult.address) {
        console.log("TracerKey connect failed.");
    }

    const address = connectResult.address;

    const mlkPromise = new Promise((resolve, reject) => {
        if (!id) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(
            id,
            {
                method: "mlkem_decrypt",
                params: [ciphertext, sharedKey_ct, address],
            },
            (response) => {
                if (response && response.result) {
                    resolve(response.result);
                } else {
                    resolve(undefined);
                }
            }
        );
    });

    const res = (await mlkPromise) as any;

    return res;
};

export const ethEncrypt = async (
    plainText: string,
    encryptionPubKey?: string,
    pqPubKey?: string
) => {
    const connectResult = await tracerkeyConnect();
    const checkExtension: any = await tracerkeyCheckExtension();
    const { id } = checkExtension || {};

    if (!connectResult || !connectResult.address) {
        console.log("TracerKey connect failed.");
    }

    const address = connectResult.address;

    const eciesEncryptPromise = new Promise((resolve, reject) => {
        if (!id) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(
            id,
            {
                method: "eth_encrypt",
                params: [plainText, encryptionPubKey, pqPubKey, address],
            },
            (response) => {
                if (response && response.result) {
                    resolve(response.result);
                } else {
                    resolve(undefined);
                }
            }
        );
    });

    const encryptedString = (await eciesEncryptPromise) as any;

    return encryptedString;
};

export const ethDecrypt = async (ciphertext: string, sharedKey_ct: string) => {
    const connectResult = await tracerkeyConnect();
    const checkExtension: any = await tracerkeyCheckExtension();
    const { id } = checkExtension || {};

    if (!connectResult || !connectResult.address) {
        console.log("TracerKey connect failed.");
    }

    const address = connectResult.address;

    const eciesDecryptPromise = new Promise((resolve, reject) => {
        if (!id) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(
            id,
            {
                method: "eth_decrypt",
                params: [ciphertext, sharedKey_ct, address],
            },
            (response) => {
                if (response && response.result) {
                    resolve(response.result);
                } else {
                    resolve(undefined);
                }
            }
        );
    });

    const decryptedString = (await eciesDecryptPromise) as any;
    return decryptedString;
};

export const ethSign = async (msg: string, usePersonalSign: boolean) => {
    const connectResult = await tracerkeyConnect();
    const checkExtension: any = await tracerkeyCheckExtension();
    const { id } = checkExtension || {};

    if (!connectResult || !connectResult.address) {
        console.log("TracerKey connect failed.");
    }

    const address = connectResult.address;

    const ethSignPromise = new Promise((resolve, reject) => {
        if (!id) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(
            id,
            {
                method: "eth_sign_2",
                params: [msg, usePersonalSign, address],
            },
            (response) => {
                if (response && response.result) {
                    resolve(response.result);
                } else {
                    resolve(undefined);
                }
            }
        );
    });

    const res = (await ethSignPromise) as any;

    return res;
};

export const mldsaSign = async (msg: string) => {
    const connectResult = await tracerkeyConnect();
    const checkExtension: any = await tracerkeyCheckExtension();
    const { id } = checkExtension || {};

    if (!connectResult || !connectResult.address) {
        console.log("TracerKey connect failed.");
    }

    const address = connectResult.address;

    const mldsaSignPromise = new Promise((resolve, reject) => {
        if (!id) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(
            id,
            {
                method: "mldsa_sign",
                params: [msg, address],
            },
            (response) => {
                if (response && response.result) {
                    resolve(response.result);
                } else {
                    resolve(undefined);
                }
            }
        );
    });

    const res = (await mldsaSignPromise) as any;

    return res;
};

export const mldsaVerify = async (msg: string, sig: string) => {
    const connectResult = await tracerkeyConnect();
    const checkExtension: any = await tracerkeyCheckExtension();
    const { id } = checkExtension || {};

    if (!connectResult || !connectResult.address) {
        console.log("TracerKey connect failed.");
    }

    const address = connectResult.address;

    const mldsaVerifyPromise = new Promise((resolve, reject) => {
        if (!id) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(
            id,
            {
                method: "mldsa_verify",
                params: [msg, sig, address],
            },
            (response) => {
                if (response && response.result) {
                    resolve(response.result);
                } else {
                    resolve(undefined);
                }
            }
        );
    });

    const res = (await mldsaVerifyPromise) as any;

    return res;
};

// export const tracerkeyMlkemEncrypt = async (
//     encryptedData: string,
//     encapsulationKey?: string
// ) => {
//     const connectResult = await tracerkeyConnect();

//     if (!connectResult || !connectResult.address) {
//         console.log("TracerKey connect failed.");
//     }

//     const address = connectResult.address;

//     const ethEncryptPromise = new Promise((resolve, reject) => {
//         if (!config.tracerkey.extensionId) {
//             resolve(undefined);
//             return;
//         }

//         C.runtime.sendMessage(
//             config.tracerkey.extensionId,
//             {
//                 method: "mlkemEncrypt",
//                 params: [encryptedData, encapsulationKey, address],
//             },
//             (response) => {
//                 if (response && response.result) {
//                     resolve(response.result);
//                 } else {
//                     resolve(undefined);
//                 }
//             }
//         );
//     });

//     // const encryptedString = (await ethEncryptPromise) as string | undefined;
//     const encryptedString = (await ethEncryptPromise) as any;

//     return encryptedString;
// };
