import { FC } from "react";
import config from "../../config/config";
import { ROUTES } from "../../constants/routes";

interface Props {
    logout?: boolean;
}

const LogoutIframe: FC<Props> = ({ logout }) => {
    console.log(logout);
    return (
        <>
            <iframe
                src={
                    logout
                        ? `${
                              config?.id?.baseUrl
                          }/logout?next_url=${`${config.uiUrl}${ROUTES.LOGOUT}`}`
                        : ""
                }
                title="id logout"
                width="0"
                height="0"
                style={{
                    // visibility: "hidden",
                    width: 0,
                    height: 0,
                    border: "none",
                }}
            />
        </>
    );
};

export default LogoutIframe;
