import { Avatar, WithWidthProps } from "@material-ui/core";
import { Button, Dropdown, Menu } from "antd";
import { FC, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, withWidth } from "@material-ui/core";
import { MdExpandMore } from "react-icons/md";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { UserContext } from "../../providers/UserProvider/UserProvider";
import { useIntl } from "react-intl";
import { EncryptionKeyContext } from "../../providers/EncryptionKeyProvider/EncryptionKeyProvider";
import CustomPartnerStatus from "../CustomPartnerStatus/CustomPartnerStatus";
import config from "../../config/config";
import COLORS from "../../constants/colors";
import clsx from "clsx";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import UserBadge from "../UserBadge/UserBadge";
import LogoutIframe from "../LogoutIframe/LogoutIframe";
import { clearStorage } from "../../utils/clearStorage";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    iconBtn: {
        padding: "0 1em 0 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: COLORS.lightblue,
        borderRadius: 25,
        [theme.breakpoints.only("xs")]: {
            background: "transparent",
        },
    },
    email: {
        fontSize: 14,
        marginLeft: "4px",
        [theme.breakpoints.only("xs")]: {
            display: "none",
        },
    },
    expandIcon: {
        [theme.breakpoints.only("xs")]: {
            display: "none",
        },
    },
}));

const UserMenu: FC<WithWidthProps & RouteComponentProps> = ({ width }) => {
    const classes = useStyles();
    const { me } = useContext(UserContext);
    const { handleOpen } = useContext(EncryptionKeyContext);
    const { formatMessage } = useIntl();
    const [logoutClick, setLogoutClick] = useState(false);

    const handleLogout = async () => {
        clearStorage().then(() => {
            setLogoutClick(true);
            setTimeout(() => {
                window.location.href = ROUTES.LOGOUT;
                setLogoutClick(false);
            }, 5000);
        });
    };

    const manageAccount = () => {
        window.location.href = `${
            config?.id?.baseUrl
        }/settings?next_url=${`${config.uiUrl}${ROUTES.DASHBOARD}&logout_url=${config?.uiUrl}${ROUTES.LOGOUT}`}`;
    };

    return (
        <div className={classes.root}>
            <Dropdown
                placement={
                    width === "sm" || width === "xs" ? "bottomRight" : "bottom"
                }
                overlayStyle={{ zIndex: 10000 }}
                overlay={() => (
                    <Menu
                        className="p-0"
                        style={{
                            border: "1px solid #fafafa",
                            width: "240px",
                            zIndex: 2,
                        }}
                    >
                        <div
                            className="d-flex justify-content-center flex-column align-items-center px-4 py-3"
                            style={{
                                backgroundColor: " #e7eff9",
                                backgroundImage:
                                    "linear-gradient(315deg, #e7eff9 0%, #e7eff9 74%)",
                            }}
                        >
                            <UserBadge />
                            <Typography
                                variant="subtitle1"
                                style={{ fontSize: 14 }}
                            >
                                {me?.email || ""}
                            </Typography>{" "}
                        </div>
                        <hr className="mt-0 mb-0" />
                        <Menu.Item
                            key="1"
                            className={clsx("px-3 py-2")}
                            onClick={!me?.activated ? handleOpen : undefined}
                        >
                            <CustomPartnerStatus
                                activated={!!me?.activated}
                                isMyProfile
                            />
                        </Menu.Item>
                        <hr className="mt-0 mb-0" />
                        <Menu.Item
                            key="2"
                            className={clsx("px-3 py-2")}
                            onClick={manageAccount}
                        >
                            <AccountCircle fontSize="small" className="mr-2" />{" "}
                            {formatMessage({ id: "auth.manage.account" })}
                        </Menu.Item>
                        <Menu.Item
                            key="3"
                            className={clsx("px-3 py-2")}
                            onClick={handleLogout}
                        >
                            <ExitToAppIcon fontSize="small" className="mr-2" />{" "}
                            {formatMessage({ id: "auth.logout" })}
                        </Menu.Item>
                    </Menu>
                )}
                trigger={["click"]}
                arrow
            >
                <Button type="text" className={classes.iconBtn}>
                    <Avatar
                        alt="Travis Howard"
                        style={{
                            height: "38px",
                            width: "38px",
                            border: "1px solid #fff",
                            background: COLORS.lightblue,
                        }}
                    >
                        <AccountCircle
                            style={{
                                fontSize: 26,
                                color: "#353B40",
                            }}
                        />
                    </Avatar>
                    <Typography
                        variant="body2"
                        noWrap
                        className={classes.email}
                    >
                        {me?.email || ""}
                    </Typography>{" "}
                    <span>
                        <MdExpandMore
                            className={clsx(classes.expandIcon, "ml-1")}
                            style={{ color: "#b7c0cd", marginTop: "-3px" }}
                        />
                    </span>
                </Button>
            </Dropdown>
            <LogoutIframe logout={logoutClick} />
        </div>
    );
};

export default withWidth()(withRouter(UserMenu));
